var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('section', {
    attrs: {
      "id": "pricing-plan"
    }
  }, [_c('transition', {
    attrs: {
      "name": "slide"
    }
  }, [_c('b-row', {
    staticClass: "pricing-card"
  }, [_c('b-col', {
    staticClass: "mx-auto d-flex justify-content-center",
    attrs: {
      "offset-sm-2": "",
      "sm": "10",
      "md": "12",
      "offset-lg": "2",
      "lg": "10"
    }
  }, [_c('b-row', [_vm.loading ? [_c('div', {
    staticClass: "d-flex justify-content-center"
  }, [_c('b-spinner', {
    attrs: {
      "variant": "primary"
    }
  })], 1)] : _vm._l(_vm.packs, function (pack) {
    return _c('b-col', {
      key: pack.id,
      attrs: {
        "md": "4"
      }
    }, [_c('b-card', {
      class: {
        current: pack.id == _vm.currentPack,
        popular: pack.popular == 1 && pack.id != _vm.currentPack
      },
      attrs: {
        "align": "center"
      }
    }, [pack.popular == 1 ? _c('div', {
      staticClass: "pricing-badge text-right",
      staticStyle: {
        "position": "absolute",
        "top": "10px",
        "right": "10px",
        "z-index": "9999"
      }
    }, [_c('b-badge', {
      attrs: {
        "variant": "light-primary",
        "pill": ""
      }
    }, [_vm._v(" Popular ")])], 1) : _vm._e(), _c('h3', [_vm._v(_vm._s(pack.title))]), pack.sub_title ? _c('b-card-text', [_vm._v(" " + _vm._s(pack.sub_title) + " ")]) : _vm._e(), pack.price ? _c('div', [_c('div', {
      staticClass: "plan-price"
    }, [_vm.monthlyPlanShow === 'monthly' ? [_c('span', {
      staticClass: "pricing-basic-value larger-price font-weight-bolder text-primary"
    }, [_vm._v(" ৳ " + _vm._s(pack.price) + "/month ")])] : [_c('span', {
      staticClass: "pricing-basic-value larger-price font-weight-bolder text-primary"
    }, [_vm._v(" ৳ " + _vm._s(pack.price) + " ")])]], 2)]) : _vm._e(), _c('b-list-group', {
      staticClass: "list-group-circle text-left"
    }, [_c('b-list-group-item', [_vm._v("Max User: " + _vm._s(pack.max_user) + " users")]), _c('b-list-group-item', [_vm._v("Role and Permissions")]), _c('b-list-group-item', [_vm._v("Human Capital Management")]), _c('b-list-group-item', [_vm._v("Leave Management")]), _c('b-list-group-item', [_vm._v("Financial Management")]), _c('b-list-group-item', [_vm._v("Task Management")]), _c('b-list-group-item', [_vm._v("Asset Management")]), _c('b-list-group-item', [_vm._v("Loan Management")]), _c('b-list-group-item', [_vm._v("Payroll Management")])], 1), _vm.$permissionAbility(_vm.SUBSCRIPTION_EDIT, _vm.permissions) && pack.id == _vm.currentPack ? _c('b-button', {
      directives: [{
        name: "ripple",
        rawName: "v-ripple.400",
        value: 'rgba(255, 255, 255, 0.15)',
        expression: "'rgba(255, 255, 255, 0.15)'",
        modifiers: {
          "400": true
        }
      }],
      staticClass: "mt-2",
      attrs: {
        "block": "",
        "variant": "outline-success"
      }
    }, [_vm._v(" Your Current Plan ")]) : _vm.$permissionAbility(_vm.SUBSCRIPTION_EDIT, _vm.permissions) && pack.id != _vm.currentPack ? _c('b-button', {
      directives: [{
        name: "ripple",
        rawName: "v-ripple.400",
        value: 'rgba(255, 255, 255, 0.15)',
        expression: "'rgba(255, 255, 255, 0.15)'",
        modifiers: {
          "400": true
        }
      }],
      staticClass: "mt-2",
      attrs: {
        "block": "",
        "variant": "primary"
      },
      on: {
        "click": function click($event) {
          pack.price <= 0 ? _vm.goContactPage() : _vm.changeSubscription(pack.id);
        }
      }
    }, [_vm._v(" Change Subscription ")]) : !_vm.$permissionAbility(_vm.SUBSCRIPTION_EDIT, _vm.permissions) && pack.id == _vm.currentPack ? _c('b-button', {
      directives: [{
        name: "ripple",
        rawName: "v-ripple.400",
        value: 'rgba(255, 255, 255, 0.15)',
        expression: "'rgba(255, 255, 255, 0.15)'",
        modifiers: {
          "400": true
        }
      }],
      staticClass: "mt-2",
      attrs: {
        "block": "",
        "variant": "outline-success"
      }
    }, [_vm._v(" Your Current Plan ")]) : _vm._e(), _c('div', {
      staticClass: "text-left"
    }, [pack.id == _vm.currentPack && _vm.cancelSub !== 5 && _vm.cancelSub !== 3 ? _c('p', {
      attrs: {
        "variant": "danger"
      },
      on: {
        "click": _vm.pauseSubscription
      }
    }, [_c('b-link', {
      staticClass: "font-weight-bold text-warning small",
      on: {
        "click": function click($event) {
          $event.stopPropagation();
          return _vm.pauseSubscription($event);
        }
      }
    }, [_vm._v(" Cancel Subscription ")])], 1) : pack.id == _vm.currentPack && (_vm.cancelSub === 5 || _vm.cancelSub === 3) ? _c('p', {
      attrs: {
        "variant": "danger"
      }
    }, [_c('b-link', {
      staticClass: "font-weight-bold text-warning small"
    }, [_vm._v(" Your subscription is already canceled. ")])], 1) : _vm._e()])], 1)], 1);
  })], 2)], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }