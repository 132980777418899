<template>
  <section id="pricing-plan">
    <!-- pricing plan cards -->
    <transition name="slide">
      <b-row class="pricing-card">
        <b-col
          offset-sm-2
          sm="10"
          md="12"
          offset-lg="2"
          lg="10"
          class="mx-auto d-flex justify-content-center"
        >
          <b-row>

            <template v-if="loading">
              <div class="d-flex justify-content-center" >
                <b-spinner variant="primary"></b-spinner>
              </div>
            </template>
            
            <template v-else>
              <b-col md="4" v-for="pack in packs" :key="pack.id">
                <b-card
                  :class="{
                    current: pack.id == currentPack,
                    popular: pack.popular == 1 && pack.id != currentPack,
                  }"
                  align="center"
                >
                <div v-if="pack.popular == 1" class="pricing-badge text-right" style="position: absolute; top: 10px; right: 10px; z-index: 9999;">
                  <b-badge variant="light-primary" pill> Popular </b-badge>
                </div>

                 
                  <h3>{{ pack.title }}</h3>

                  <b-card-text v-if="pack.sub_title">
                    {{ pack.sub_title }}
                  </b-card-text>

                  <div v-if="pack.price">
                    <div class="plan-price">
                      <template v-if="monthlyPlanShow === 'monthly'">
                          <span
                          
                            class="pricing-basic-value larger-price font-weight-bolder text-primary"
                          >
                          ৳ {{ pack.price }}/month
                          </span>

                      </template> 

                      <template v-else>
                        <span
                          
                            class="pricing-basic-value larger-price font-weight-bolder text-primary"
                          >
                          ৳ {{ pack.price }}
                          </span>                      
                      </template>
                      
                    </div>
                  </div>

                  <b-list-group class="list-group-circle text-left">
                    <b-list-group-item>Max User: {{ pack.max_user }} users</b-list-group-item>
                    <b-list-group-item>Role and Permissions</b-list-group-item>
                    <b-list-group-item
                      >Human Capital Management</b-list-group-item
                    >
                    <b-list-group-item>Leave Management</b-list-group-item>
                    <b-list-group-item>Financial Management</b-list-group-item>
                    <b-list-group-item>Task Management</b-list-group-item>
                    <b-list-group-item>Asset Management</b-list-group-item>
                    <b-list-group-item>Loan Management</b-list-group-item>
                    <b-list-group-item>Payroll Management</b-list-group-item>
                  </b-list-group>


                  <b-button
                    v-if="$permissionAbility(SUBSCRIPTION_EDIT, permissions) && pack.id == currentPack"
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    block
                    class="mt-2"
                    variant="outline-success"
                  >
                    Your Current Plan
                  </b-button>


                  <b-button
                     v-else-if="$permissionAbility(SUBSCRIPTION_EDIT, permissions) && pack.id != currentPack"
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    block
                    class="mt-2"
                    variant="primary"
                    @click="
                      pack.price <= 0
                        ? goContactPage()
                        : changeSubscription(pack.id)
                    "
                  >
                    Change Subscription
                  </b-button>

                  <b-button
                    v-else-if="!$permissionAbility(SUBSCRIPTION_EDIT, permissions) && pack.id == currentPack"
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    block
                    class="mt-2"
                    variant="outline-success"
                  >
                    Your Current Plan
                  </b-button>

                  <div class="text-left">
                    <p v-if="pack.id == currentPack && cancelSub !== 5 && cancelSub !== 3"
                      variant="danger"
                      @click="pauseSubscription"
                    >
                      <b-link class="font-weight-bold text-warning small" @click.stop="pauseSubscription">
                        Cancel Subscription
                      </b-link>

                    </p>

                    <p v-else-if="pack.id == currentPack && (cancelSub === 5 || cancelSub === 3)"
                      variant="danger"
                    >
                      <b-link class="font-weight-bold text-warning small" >
                        Your subscription is already canceled. 
                      </b-link>

                    </p>


                  </div>

                </b-card>

              </b-col>
            </template>

          </b-row>
        </b-col>
      </b-row>
    </transition>
  </section>
</template>

<script>
import {
  BFormCheckbox,
  BLink,
  BRow,
  BCol,
  BCard,
  BImg,
  BCardText,
  BListGroup,
  BListGroupItem,
  BButton,
  BBadge,
  BForm,
  BSpinner,
  BFormGroup,
  BFormInput,
  BFormRadioGroup,
  BFormCheckboxGroup,
  BAlert,
} from "bootstrap-vue";
import AppCollapse from "@core/components/app-collapse/AppCollapse.vue";
import AppCollapseItem from "@core/components/app-collapse/AppCollapseItem.vue";
import Ripple from "vue-ripple-directive";
import { mapGetters } from "vuex";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import {
  SUBSCRIPTION_EDIT,
} from "@/helpers/permissionsConstant";

export default {
  name: "Subscriptions",
  components: {
    BFormCheckbox,
    BButton,
    BCardText,
    BListGroup,
    BListGroupItem,
    BLink,
    BRow,
    BCol,
    BCard,
    BBadge,
    BImg,
    AppCollapseItem,
    AppCollapse,
    BForm,
    BSpinner,
    BFormGroup,
    BFormInput,
    BFormRadioGroup,
    BFormCheckboxGroup,
    BAlert,

  },
  directives: {
    Ripple,
  },

  data() {
    return {
      // Permissions
      SUBSCRIPTION_EDIT,

      email: "",
      packageId: "",
      companyName: "",

      nameError: false,
      emailError: false,

      status: "monthly",
      monthlyPlanShow: "monthly",
      pricing: {},

      packs: [],

      currentPack: "",
      loading: false,
      cancelSub: "",

    };
  },

  computed: {
    ...mapGetters({
      authUser: "userModule/getUser",
      permissions: "userModule/getPermissions",

    }),
    getPaymentMethod() {
      return this.$store.getters["paymentMethodModule/getPaymentMethod"];
    },
  },

  async created() {
    try {
      this.loadItems();
    } catch (error) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Error",
          icon: "BellIcon",
          variant: "danger",
          text: error?.response?.data?.message,
        },
      });
    }
  },

  methods: {
    async getSubscriptionPricingItems() {
      return await this.$api.get("api/subscription-pricing", {
        params: {
          payment_method: this.getPaymentMethod,
        }
      });
    },

    async getCurrentPackage(params) {
      return await this.$api.get("api/current-subscription-package", {
        params: {
          company_id: params.company_id,
        },
      });
    },

    async postPauseSubscription(params) {
      return await this.$api.post("api/sslcommerz/pause-resume-cancel-subscription", {
        company_id: params.company_id,
        subscription_status: params.subscription_status,
      });
    },

    async pauseSubscription() {
      const response = await this.postPauseSubscription({
        company_id: this.authUser?.company_id,
        subscription_status: 3
      });

      if (response.data.status == "success") {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Success",
            icon: "BellIcon",
            variant: "success",
            text: response.data.message,
          },
        });

      } else {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Error",
            icon: "BellIcon",
            variant: "danger",
            text: response.data.message,
          },
        });
      }
    },

    async loadItems() {

      this.loading = true;

      const getSubscriptionPricingItems =
        await this.getSubscriptionPricingItems({});

      const getCurrentPackages = await this.getCurrentPackage({
        company_id: this.authUser?.company_id,
      });

      this.currentPack = getCurrentPackages.data?.data?.package_id;
      this.cancelSub = getCurrentPackages.data?.data?.status;

      if (this.monthlyPlanShow === "monthly") {
        this.packs = getSubscriptionPricingItems.data.filter(
          (pack) => pack.billing_cycle === "monthly"
        );
      } else if (this.monthlyPlanShow === "yearly") {
        this.packs = getSubscriptionPricingItems.data.filter(
          (pack) => pack.billing_cycle === "yearly"
        );
      } else {
        this.packs = getSubscriptionPricingItems.data.filter(
          (pack) => pack.billing_cycle === "life_time"
        );
      }

      this.loading = false;

    },

    async changeSubscription(packageId) {
      const response = await this.$api.post("/api/manual-payment/upgrade-package-payment", {
        id: this.authUser?.company_id,
        package_id: packageId,
      });
      this.loadItems();
      if (response.data.status == "success") {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Success",
            icon: "BellIcon",
            variant: "success",
            text: response.data.message,
          },
        });

        setTimeout(() => {
          this.loadItems();
        }, 2000);

      } else {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Error",
            icon: "BellIcon",
            variant: "danger",
            text: response.data.message,
          },
        });
      }
    },

    goContactPage() {
      window.location.href = "https://projectx.zone/contact-us/";
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/pages/page-pricing.scss";

.slide-enter-active,
.slide-leave-active {
  transition: transform 0.5s ease;
}

.slide-enter,
.slide-leave-to {
  transform: translateX(-100%);
}

.current {
  border: 1px solid #28c76f;
}

.larger-price {
  font-size: 2rem; 
}

.custom-link {
  color: #7367f0;
}
</style>